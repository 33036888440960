<template>
	<nav class="nav-primary">
		<a v-if="!scrolled" class="logo" href="/">
			<img src="@/assets/images/NWLOGO.svg" alt="" />
		</a>
		<a v-if="scrolled" class="logo" href="/">
			<img src="@/assets/images/NWLOGO-black.svg" alt="" />
		</a>
		<div>
			<a 
				v-for="item in content.navigation_items" 
				:key="item.name" :href="item.url"
			>
				{{item.name}}
			</a>
			<a class="btn" href="/abonnemang">Teckna Abonnemang</a>
			<a class="btn green bokaBtn" href="https://www.matchi.se" target="_blank">Boka</a>
		</div>
	</nav>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '@/assets/apiLink.js'
export default {
	name: 'Nav',
	props: {
		scrolled: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			baseApiUrl: baseUrl,
			content: {},
		}
	},
	mounted() {
		axios.get(this.baseApiUrl + '/umbraco/api/settings/getsettings').then((response) => {
			this.content = response.data
		})
	},
}
</script>

<style scoped lang="scss">
.nav-primary {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 87px;
	background-color: inherit;

	a:visited {
		color: #fff;
	}

	&.scrolled {
		a:visited {
			color: #000;
		}
		div a {
			color: #000;
		}
		.btn {
			border: solid 2px #000;
		}
	}

	div {
		display: flex;
		align-items: center;

		a {
			@include paragraph-small;

			text-transform: uppercase;
			letter-spacing: 0.7px;
			margin-right: 30px;
			font-weight: 600;
			color: #fff;
		}
		& > a:nth-of-type(3) {
			margin-right: 45px;
		}
		a:last-child {
			margin-right: 0;
		}
	}

	.logo {
		width: 200px;
		margin-top: 10px;
	}

	.btn {
		padding: 0.5px 35px;
		border-radius: 25px;
		border: solid 2px #fff;
		&.green {
			background-color: $color-green;
			border: $color-green;
		}
	}
	@media (max-width: 1024px) {
		display: none;
	}
}

.pinkRibbonTheme {
	.nav-primary {
		.bokaBtn {
			background-color: $color-darkpink !important;
			border: $color-darkpink !important;
		}
	}
}
</style>
